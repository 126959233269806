@import '../../../styles/customMediaQueries.css';

.root {
  composes: marketplaceModalFormRootStyles from global;

  justify-content: flex-start;

  @media (--viewportMedium) {
    justify-content: space-between;
  }
}

.name {
  display: flex;
  justify-content: space-between;
  /* margin-top: 24px; */

  @media (--viewportMedium) {
    /* margin-top: 32px; */
  }
}
.email {
  margin: 24px 0px 0px 0px;
}

.firstNameRoot {
  width: calc(34% - 9px);
}

.lastNameRoot {
  width: calc(66% - 9px);
}

.password {
  composes: marketplaceModalPasswordMargins from global;
}

.bottomWrapper {
  composes: marketplaceModalBottomWrapper from global;
}

.password {
  margin-bottom: 22px;
}
.newsletter {
  margin: 30px 0px 0px 0px;
}
.concieve {
  margin: 30px 0px 0px 0px;
}
.modern {
  margin-top: 25px;
}
.roots {
  font-size: 13px;
  text-align: left;
  /* composes: marketplaceModalHelperLink from global;
    color: var(--marketplaceColor);
    text-align: left; */
}

.hidden {
  display: none;
}
